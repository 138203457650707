/** 
 * Global store for global components used by App.vue
 */

import { reactive } from "vue";
import { validateSettings } from "../validation/validators.js";

const default_settings = {
    // Settings file version, a simple running number
    version: 0,

    // Localization settings
    // Default here should match the one passed to createI18n
    locale: LAULUKIRJA_DEFAULT_LOCALE,

    // Content settings
    dessert: false,
    alphabetical: false,
    lowquality: true,

    // Visual
    dark: false,

    // Favorite songs, simply a list of integers (song_ids)
    favorites: []
}

export default {
    state: reactive({
        loading: false, // Global loading state
        settings: null
    }),

    setLoading(isloading) {
        // Make sure we are casting to a bool
        this.state.loading = !!isloading;
    },

    getSettings() {
        // Load truth from localStorage if it isn't yet
        if (!this.state.settings) {
            console.log("[laulukirja-storage] No loaded settings");
            let localstorage_ver = JSON.parse(localStorage.getItem(LAULUKIRJA_STORAGE_KEY) || "null");
            if (!localstorage_ver) {
                // If this the first time user ever enters the app, save a copy of default settings
                console.log("[laulukirja-storage] First time user, saving defaults");
                localStorage.setItem(LAULUKIRJA_STORAGE_KEY, JSON.stringify(default_settings));
                this.state.settings = default_settings;
            } else {
                // We have settings (they could still be invalid)
                console.log("[laulukirja-storage] Settings in localStorage already")
                this.state.settings = localstorage_ver;
            }
        }

        return this.state.settings;
    },

    saveSettings(settings, nuke) {
        if (nuke) {
            this.state.settings = default_settings;
            localStorage.removeItem(LAULUKIRJA_STORAGE_KEY);
        } else {
            let builtsettings = Object.assign(this.state.settings, settings);
            if (validateSettings(builtsettings)) {
                // Persist to localStorage
                localStorage.setItem(LAULUKIRJA_STORAGE_KEY, JSON.stringify(builtsettings));
            } else {
                console.warn(validateSettings.errors);
            }
        }
    }
}
