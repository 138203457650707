<template>
    <li class="mb-3 flex justify-between items-center bg-white shadow rounded-lg opt-song-item">
        <!-- Pad the actual link instead of containg list element to make more clickable space -->
        <router-link :to="'/' + song.song_id" class="text-2xl p-4 w-full">
            <span class="font-extrabold">{{ song.song_id }}</span> {{ song.name }}
        </router-link>
    </li>
</template>

<script>
    export default {
        name: "song-list-item",
        props: ["song"]
    }
</script>
