<template>
    <div :class="alertClass" class="flex flex-row p-3 rounded">
        <!-- Alert icon -->
        <!-- Margin should match alert padding -->
        <div class="flex-initial mr-3 self-center">
            <svg class="w-6 h-6 stroke-current text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="alertIcon"></path>
            </svg>
        </div>
        <!-- Alert content -->
        <div class="flex-grow flex flex-wrap">
            <!-- Split into content header and actual content -->
            <!-- Displayed inline if header + content can fit onto same line.
                Otherwise, displayed stacked -->
            <span v-text="title" class="font-bold text-white mr-3"></span>
            <span v-text="message" class="text-white"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "alert",
        props: ["type", "title", "message"],

        computed: {
            alertClass() {
                switch (this.type.toLowerCase()) {
                    case "success":
                        return "bg-green-500";
                    case "info":
                        return "bg-blue-500";
                    case "danger":
                        return "bg-red-500";
                    case "warning":
                        return "bg-yellow-500";
                    default:
                        return "bg-gray-500";
                }
            },

            /** Returns SVG path for current alert level */
            alertIcon() {
                switch (this.type.toLowerCase()) {
                    case "success":
                        return "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z";
                    case "info":
                        return "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z";
                    case "danger":
                        return "M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z";
                    case "warning":
                        return "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z";
                    default:
                        return "";
                }
            }
        }
    }
</script>
