import { createRouter, createWebHistory } from "vue-router";

/**
 * Import views.
 *
 * Home view is stored in main chunk with Vue runtime since it's the first rendered view.
 * Other views are loaded lazily.
 */

import Home from "../views/Home.vue";
const Song = () => import(/* webpackChunkName: "song" */ "../views/Song.vue");
// const AddSong = () => import(/* webpackChunkName: "song_management" */ "../views/AddSong.vue");
const Settings = () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue");

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/:song_id",
        name: "Song",
        component: Song
    },
    // {
    //     path: "/song/add",
    //     name: "Add a new song",
    //     component: AddSong
    // },
    {
        path: "/settings",
        name: "Settings",
        component: Settings
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
