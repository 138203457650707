<i18n>
{
    "en": {
        "search": "Search..."
    },
    "fi": {
        "search": "Etsi..."
    }
}
</i18n>

<template>
    <div class="relative my-6">
        <input :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :placeholder="$t('search')"
            type="search"
            class="shadow-lg w-full rounded border-0 p-3"
        >
        <div class="absolute -inset-y-0.5 right-0 mt-3 mr-4">
            <svg class="w-7 h-7 stroke-current text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "search",
        props: ["modelValue"],
        emits: ["update:modelValue"]   
    }
</script>
