/**
 * JSON schema validators.
 */

import Ajv from "ajv";
const ajv = new Ajv();

const setting_schema = require("./schema/settings.json");
const settings_validator = ajv.compile(setting_schema);

export {
    settings_validator as validateSettings,
};
