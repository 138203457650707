<template>
    <!-- Site header -->
    <router-link to="/" class="block text-center">
        <!-- Heroicon music-note -->
        <svg 
            class="h-11 animate-loading inline-block align-baseline -mb-3 md:-mb-2 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            :class="{ 'animation-stopped': stopped }"
            ref="loading_icon"
        >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
        </svg>
        <h1 class="text-4xl lg:text-5xl my-8 inline-block font-extrabold">Laulukirja</h1>
    </router-link>

    <!-- Container's max-width is controlled in tailwind.config.js -->
    <div class="container mx-auto px-4">
        <!-- Visited songs are cached until page reload -->
        <!-- This also allows us keep search results in Home view without explicit caching -->
        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" :key="$route.fullPath"/>
            </keep-alive>
        </router-view>
    </div>
</template>

<script>
    import Alert from "./components/alert.vue";

    import LoadingStore from "./util/store.js";

    export default {
        name: "Application",
        components: { Alert },
        data: () => ({
            everywhere: LoadingStore,
            stopped: false
        }),
        watch: {
            /**
             * Hack for gracefully stopping loading animation without jank.
             * In order for animation to play to the end,
             * animation-name css property needs to be cleared by JS when
             * animationiteration event is fired.
             *
             * More details:
             * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Tips#Stopping_an_animation
             */
            'everywhere.state.loading'(state) {
                if (state === false) {
                    this.$refs["loading_icon"]
                        .addEventListener("animationiteration", () => this.stopped = true, { once: true });
                } else {
                    this.stopped = false;
                }
            }
        }
    }
</script>
