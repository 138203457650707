import "./global.css"

import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from './App.vue'
import router from './router'

import linkify from "./plugin/linkify.js";

import Store from "./util/store.js";

const i18n = createI18n({
    legacy: true,
    locale: Store.getSettings().locale,
    fallbackLocale: "en",
    messages: {}
});

createApp(App)
    .use(linkify)
    .use(i18n)
    .use(router)
    .mount('#app');
