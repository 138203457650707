<i18n>
    {
        "en": {
            "up": "Back to top"
        },
        "fi": {
            "up": "Ylös"
        }
    }
</i18n>

<template>
    <button type="button" @click="scrollUp()" class="border border-indigo-500 bg-indigo-500 text-white px-6 py-1 rounded text-lg font-bold">
        <svg class="w-5 h-5 inline-block -mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path>
        </svg>
        {{ $t("up") }}
    </button>
</template>

<script>
    export default {
        name: "scroll-up-button",
        methods: {
            scrollUp() {
                window.scroll({
                    left: 0,
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }
</script>
