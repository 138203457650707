/**
 * Laulukirja API client
 *
 * Sets required Authorization headers automatically
 */


// fetch() compatible options
const options = {
    mode: "cors",
}

export function song(id) {
    return fetch(LAULUKIRJA_API + `/song/${id}`, options);
}

export function song_list() {
    return fetch(LAULUKIRJA_API + `/song/`, options);
}
