<template>
    <!-- Search bar for filtering songs, provides 'query' model -->
    <search v-model="query" />
    <!-- Actual list of songs -->
    <ul class="mb-8">
        <song-list-item
            v-for="song in filteredSongs"
            :song="song"
            :key="song.id"
         />
    </ul>
    <!-- Scroll back up -->
    <div class="mb-8 text-right">
        <scroll-up-button />
    </div>
</template>

<script>
    import Search from "../components/search.vue";
    import SongListItem from "../components/song-list-item.vue";
    import ScrollUpButton from "../components/scroll-up-button.vue";

    import Store from "../util/store.js";
    import { song_list } from "../util/api.js";

    const collator = new Intl.Collator(); // For fast string comparison

    let settings = Store.getSettings();

    export default {
        components: { SongListItem, Search, ScrollUpButton },

        created() {
            // All our components are cached using keep-alive directive
            // So this function is called only once per page reload

            Store.setLoading(true);

            song_list()
                .then(response => response.json())
                .then(songs => {
                    this.songs = songs.data
                })
                .catch(err => console.log(err))
                .finally(() => Store.setLoading(false));
        },

        data: () => ({
            query: "",
            songs: []
        }),

        computed: {
            filteredSongs() {
                let filtered = this.songs.filter(song => {
                    // Check if we want to filter by number or name
                    if (this.query.startsWith("#")) {
                        // No need for lowercase conversion here
                        // since we're only dealing with numbers
                        return song.song_id.toString().indexOf(this.query.slice(1)) > -1;
                    } else {
                        return song.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
                    }
                });

                if (settings.alphabetical) {
                    return filtered.sort((a, b) => collator.compare(a.name, b.name));
                } else {
                    return filtered;
                }
            }
        }
    }
</script>
