/**
 * Vue linkify-it plugin
 */

const LinkifyIt = require("linkify-it");

export default {
    install(app, options) {
        // Setup linkify itself
        let linkify = new LinkifyIt()
            .add("ftp:", null)
            .add("mailto:", null)
            .set({ fuzzyIP: false });

        // Expose global method for linkifying stuff
        app.config.globalProperties.$linkify = (link) => {
            if (!link) {
                // Either undefined or null (?)
                return link;
            }


            let match = linkify.match(link);
            if (match && match[0]) {
                return `<a href="${match[0].url}" rel="noopener noreferrer">${match[0].raw}</a>`
            } else {
                return link;
            }
        }
    }
}

